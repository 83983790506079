<template>
  <b-card no-body class="business-card">
    <b-card-header class="pb-1">
      <b-card-title> {{ $t("language") }}</b-card-title>
      <feather-icon icon="MoreVerticalIcon" size="18" class="cursor-pointer" />
    </b-card-header>

    <b-card-body>
      <div class="business-items">
        <div class="business-item">
          <div class="d-flex align-items-center justify-content-between">
            <div class="row align-items-center">
              <b-link
                :class="language == 'en' ? 'active-lang nav-link' : 'nav-link'"
                @click="toggleLanguage('en')"
              >
                <img src="/images/flags/english.png" class="flag-icon" />
              </b-link>
              <b-link
                :class="language == 'de' ? 'active-lang nav-link' : 'nav-link'"
                @click="toggleLanguage('de')"
              >
                <img src="/images/flags/german.png" height="100%" class="flag-icon" />
              </b-link>
            </div>
            <b-badge
              variant="light-secondary"
              v-html="language == 'en' ? 'English' : 'Deutsch'"
            >
            </b-badge>
          </div>
        </div>
      </div>

      <!-- button -->
    </b-card-body>
  </b-card>
</template>

<script>
import Ripple from "vue-ripple-directive";
import ToastificationContent from "@core/components/toastification/ToastificationContent";
import i18n from "@/libs/i18n";
export default {
  directives: {
    Ripple,
  },
  data() {
    return {
      allow: false,
      decline: false,
      language: this.$i18n.locale,
    };
  },
  props: ["buyer"],
  created() {
    this.getCompany();
    if (this.buyer == 0) {
      this.allow = false;
      this.decline = true;
    } else if (this.buyer == 1) {
      this.allow = true;
      this.decline = false;
    }
    
  },
  methods: {
    getCompany() {
      this.$http
        .post("/company/show/" + this.$store.state.app.company.value.id, {})
        .then((res) => {
          if (res) {
            this.language = res.data.value.language;
            
            this.toggleLanguage(this.language);
            
          }
        });
    },
    toggleLanguage(language) {
      this.$i18n.locale = language;
      this.language = language;
      this.$store.commit("app/UPDATE_LANGUAGE", language);
      document.getElementById("updateSideBar").click();
      this.$http.post("/company/update/language", { language: this.language });
    },
    submit() {
      this.$http
        .post("/profile/update/buyer", { buyer: this.allow ? 1 : 0 })
        .then((res) => {
          if (res) {
            document.getElementById("updateSideBar").click();
          }
        });
      let message = "";
      if (this.allow) {
        message = i18n.t("acting_as_a_buyer_allowed");
      } else {
        message = i18n.t("acting_as_a_buyer_denied");
      }
      this.$toast({
        component: ToastificationContent,
        props: {
          title: message,
          icon: "SuccessIcon",
          variant: "success",
        },
      });
    },
  },
};
</script>
<style>
.active-lang {
  box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px,
    rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
  border: 2px solid rgb(10, 118, 161);
  border-radius: 50%;
    padding: 5px !important;
}

    .flag-icon {
        width: 30px;
        border-radius: 100%;
        height: 30px;
        object-fit: cover;
        object-position: center;
    }
</style>
