<template>
    <div id="user-profile">
        <!-- profile info  -->
        <section v-if="!load" id="profile-info">
            <b-row>
                <b-col lg="12" cols="12" order="1">
                    <welcome/>
                </b-col>
                <b-col xl="3" lg="12" order="1">
                    <profile-latest-photos :latest-image="profileData.latestPhotos[0]"/>
                    <languages></languages>
                </b-col>
                <b-col class="col-xl-9 col-lg-12 col-md-12" order="2" v-if="!showLoader">
                    <profile-about
                        v-if="!editInfo"
                        @showInfo="showInfo"
                        :aboutData="profileData.userAbout"
                    ></profile-about>
                    <edit-information
                        v-else
                        @showInfo="showInfo"
                        :user="profileData.userAbout"
                        @editForm="editForm"
                        @changePassword="changePassword"
                        :passwordErrors="passwordErrors"
                    ></edit-information>

                </b-col>
                <b-col cols="3" v-else>
                    <img src="/new-loader.svg"/>
                    <!-- {{ JSON.stringify(company_zefix_data) }} -->
                </b-col>
            </b-row>
        </section>
        <div v-else>
            <img src="/new-loader.svg"/>
        </div>
        <!--/ profile info  -->
    </div>
</template>

<script>
  import ProfileAbout from "../../components/profile/ProfileAbout.vue";
  import ProfileLatestPhotos from "../../components/profile/ProfileLatestPhotos.vue";
  import EditInformation from "../../components/profile/EditInformation.vue";
  import User from "../../components/profile/User.vue";
  import Welcome from "../../components/profile/Welcome.vue";
  import ToastificationContent from "@core/components/toastification/ToastificationContent";
  import Market from "@/components/profile/Market.vue";
  import Languages from "@/components/profile/Languages.vue";
  /* eslint-disable global-require */
  export default {
    components: {
      ProfileLatestPhotos,
      ProfileAbout,
      User,
      EditInformation,
      Welcome,
      Market,
      Languages,
    },
    data() {
      return {
        passwordErrors: {},
        editInfo: false,
        buyer: 0,
        countries_code: [],
        showLoader: true,
        profileData: {
          userAbout: {
            first_name: "",
            last_name: "",
            birthday: "",
            email: "",
            gender: "",
            position_in_the_company: "",
            mobile: "",
            address: "",
            address_nr: "",
            city: "",
            zip: "",
            nationality: "",
            country_name: "",
            country_id: "",
          },
          latestPhotos: [
            {
              img: "/img/13-small.d796bffd.png",
              text: "profile_picture",
            },
          ],
        },
        load: true,
      };
    },
    created() {
      this.getData();
    },
    methods: {
      getData() {
        this.$http.post("/profile/show").then((myRes) => {
          this.$http.post("/countries").then((res) => {
            if (res) {
              let data = myRes.data.value;
              this.buyer = data.buyer;
              console.log(res);

              res.data.value.map((item) => {
                this.countries_code.push(item);
                if (item.id == data.country_id) {
                  this.profileData.userAbout.country_name =
                      this.$store.state.app.language == "en"
                          ? item.name
                          : item.name_de;
                  this.profileData.userAbout.country_id = data.country_id;
                }
              });

              this.profileData.userAbout = {
                email: data.email,
                mobile: data.tel,
                gender: data.gender,
                first_name: data.first_name,
                last_name: data.last_name,
                birthday: data.birthday,
                address: data.address,
                address_nr: data.address_nr,
                zip: data.zip,
                city: data.city,
                country_id: data.country_id,
                country: data.country.name,
                iso_code: data.iso_code,
              };

              if (
                  this.$store.state.app.watchCompany.value &&
                  this.$store.state.app.watchCompany.value.role != "buyer"
              ) {
                this.profileData.userAbout.position = data.position;
              }
              this.load = false;
              this.showLoader = false;
            }
          });
        });
      },

      showInfo(bool) {
        this.getData();
        this.editInfo = bool;
      },
      editForm(data) {
        let updateData = {
          first_name: data.first_name,
          last_name: data.last_name,
          country_id: data.country_id,
          city: data.city,
          tel: data.mobile,
          gender: data.gender,
          birthday: data.birthday,
          position: data.position,
          address: data.address,
          address_nr: data.address_nr,
          zip: data.zip,
          iso_code: data.iso_code,
        };
        this.showLoader = true;
        this.$http.post("/profile/update", updateData).then((res) => {
          if (res) {
            this.getData();
            document.getElementById("header-info").click();
            this.$toast({
              component: ToastificationContent,
              props: {
                title: "Profile updated successfully",
                icon: "SuccessIcon",
                variant: "success",
              },
            });
            this.editInfo = false;
          }
        });

        // this.$http.post("/update/language", {language: data.language});
      },
      changePassword(data) {
        let updatePasswordData = {
          current_password: data.current_password,
          new_password: data.new_password,
          confirm_password: data.confirm_password,
        };
        this.showLoader = true;
        this.passwordErrors = {}; // Сброс ошибок перед новым запросом
        this.$http.post("/profile/update/password", updatePasswordData)
        .then((res) => {
          if (res) {
            this.getData();
            document.getElementById("header-info").click();
            this.$toast({
              component: ToastificationContent,
              props: {
                title: "Password Change successfully",
                icon: "SuccessIcon",
                variant: "success",
              },
            });
            this.editInfo = false;
          }
        })
        .catch((error) => {
          console.log(error)
          this.showLoader = false;
          if (error.response && error.response.status === 422) {
            this.passwordErrors = error.response.data.errors;
          } else {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: "An error occurred",
                icon: "ErrorIcon",
                variant: "danger",
              },
            });
          }
        });
      }

    },
  };
  /* eslint-disable global-require */
</script>

<style lang="scss">
    @import "@core/scss/vue/pages/page-profile.scss";
</style>
