<template>
  <b-card no-body class="business-card">
    <b-card-header class="pb-1">
      <b-card-title> {{$t('acting_as_a_buyer')}}</b-card-title>
      <feather-icon icon="MoreVerticalIcon" size="18" class="cursor-pointer" />
    </b-card-header>

    <b-card-body>
      <div class="business-items">
        <div class="business-item">
          <div class="d-flex align-items-center justify-content-between">
            <b-form-checkbox
              v-model.trim="allow"
              @change="(allow = true), (decline = false)"
            >
              {{$t('yes')}}
            </b-form-checkbox>
            <b-badge variant="light-success">  {{$t('allow')}}</b-badge>
          </div>
        </div>
        <div class="business-item">
          <div class="d-flex align-items-center justify-content-between">
            <b-form-checkbox
              v-model.trim="decline"
              @change="(allow = false), (decline = true)"
            >
             {{$t('no')}}  
            </b-form-checkbox>
            <b-badge variant="light-primary"> {{$t('deny')}} </b-badge>
          </div>
        </div>
      </div>

      <!-- button -->
      <b-button
        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
        variant="outline-primary"
        block
        @click="submit"
      >
        {{ $t("save") }}
      </b-button>
    </b-card-body>
  </b-card>
</template>

<script>
import Ripple from "vue-ripple-directive";
import ToastificationContent from "@core/components/toastification/ToastificationContent";
import i18n from '@/libs/i18n';
export default {
  directives: {
    Ripple,
  },
  data() {
    return {
      allow: false,
      decline: false,
    };
  },
  props: ["buyer"],
  created() {
    if (this.buyer == 0) {
      this.allow = false;
      this.decline = true;
    } else if (this.buyer == 1) {
      this.allow = true;
      this.decline = false;
    }
  },
  methods: {
    submit() {
      this.$http
        .post("/profile/update/buyer", { buyer: this.allow ? 1 : 0 })
        .then((res) => {
          if (res) {
            document.getElementById('updateSideBar').click();
          }
        });
      let message = "";
      if (this.allow) {
        message = i18n.t('acting_as_a_buyer_allowed');
      } else {
        message = i18n.t('acting_as_a_buyer_denied'); 
      }
      this.$toast({
        component: ToastificationContent,
        props: {
          title: message,
          icon: "SuccessIcon",
          variant: "success",
        },
      });
    },
  },
};
</script>
