<template>
  <b-card no-body class="card-developer-meetup">
    <div class="bg-light-primary rounded-top text-center">
      <b-img src="/images/about-profile.png" alt="Meeting Pic" height="170" />
    </div>
    <b-card-body v-if="aboutData">
      <div class="meetup-header d-flex align-items-center">
        <div class="my-auto">
          <b-card-title class="mb-25"> {{ $t("profile") }} </b-card-title>
          <b-card-text class="mb-0">
            {{ $t("your_information") }}
          </b-card-text>
        </div>
      </div>
      <div class="row">
        <div class="col-md-6">
          <b-media
            no-body
            v-for="(data, key, index) in aboutData"
            :key="index"
            v-if="index < 6 && data"
            class="col-md-12"
          >
            <b-media-aside class="mr-1">
              <b-avatar rounded variant="light-primary" size="34">
                <feather-icon icon="InfoIcon" size="18" />
              </b-avatar>
            </b-media-aside>
            <b-media-body>
              <h6 class="mb-0">
                <span
                  v-if="$t(key) == 'Gender' || $t(key) == 'Geschlecht'"
                  v-html="data == 0 ? $t('mr') : $t('mrs')"
                ></span>
                <span
                  v-else-if="
                    $t(key) == 'Date of birth' || $t(key) == 'Geburtsdatum'
                  "
                >
                  {{ data }}
                </span>
                <span v-else>{{ data }}</span>
              </h6>
              <small> {{ $t(key) }} </small>
            </b-media-body>
          </b-media>
        </div>
        <div class="col-md-6">
          <b-media
            no-body
            v-for="(data, key, index) in aboutData"
            :key="index"
            v-if="index >= 6 && key != 'country_id' && key != 'iso_code' && data"
            class="col-md-12"
          >
            <b-media-aside class="mr-1">
              <b-avatar rounded variant="light-primary" size="34">
                <feather-icon icon="InfoIcon" size="18" />
              </b-avatar>
            </b-media-aside>
            <b-media-body>
              <h6 class="mb-0">
                {{ data }}
              </h6>
              <small> {{ $t(key) }}</small>
            </b-media-body>
          </b-media>
        </div>
      </div>
      <div class="row">
        <div class="col-md-6"></div>
        <div class="col-md-6">
          <div class="text-end justify-content-end mt-1" align="end">
            <b-button
              variant="outline-primary"
              @click="$emit('showInfo', true)"
              >{{ $t("edit_information") }}</b-button
            >
          </div>
        </div>
      </div>
      <!-- avatar group -->
    </b-card-body>
 
  </b-card>
</template>

<script>
import { VBTooltip } from "bootstrap-vue";
import AppTimeline from "@core/components/app-timeline/AppTimeline.vue";
import AppTimelineItem from "@core/components/app-timeline/AppTimelineItem.vue";
export default {
  components: {
    AppTimeline,
    AppTimelineItem,
  },
  directives: {
    "b-tooltip": VBTooltip,
  },
  props: {
    aboutData: {
      type: Object,
      default: () => {},
    },
  },

  computed: {
    getCurrentDate() {
      let today = new Date();
      return today.getDate();
    },
    getCurrentDayLong() {
      let today = new Date();
      return new Date(today).toLocaleString("de-DE", { weekday: "long" });
    },
  },
  methods: {
    changeColor(index) {
      let color = "";
      if (index == 0 || index == 4 || index == 8) {
        color = "primary";
      } else if (index == 1 || index == 5 || index == 9 || index == 12) {
        color = "info";
      } else if (index == 3 || index == 6 || index == 10) {
        color = "success";
      } else if (index == 2 || index == 7 || index == 11) {
        color = "info";
      }
      return color;
    },
  },
  data() {
    return {
      countries: [],
      showLoader: false,
      avatars: [
        {
          avatar: require("@/assets/images/portrait/small/avatar-s-9.jpg"),
          fullName: "Billy Hopkins",
        },
        {
          avatar: require("@/assets/images/portrait/small/avatar-s-6.jpg"),
          fullName: "Amy Carson",
        },
        {
          avatar: require("@/assets/images/portrait/small/avatar-s-8.jpg"),
          fullName: "Brandon Miles",
        },
        {
          avatar: require("@/assets/images/portrait/small/avatar-s-7.jpg"),
          fullName: "Daisy Weber",
        },
        {
          avatar: require("@/assets/images/portrait/small/avatar-s-20.jpg"),
          fullName: "Jenny Looper",
        },
      ],
      mediaData: [
        {
          avatar: "CalendarIcon",
          title: "Sat, May 25, 2020",
          subtitle: "10:AM to 6:PM",
        },
        {
          avatar: "MapPinIcon",
          title: "Central Park",
          subtitle: "Manhattan, New york City",
        },
      ],
      avatars: [
        {
          userImg: require("@/assets/images/portrait/small/avatar-s-9.jpg"),
          name: "Billy Hopkins",
        },
        {
          userImg: require("@/assets/images/portrait/small/avatar-s-6.jpg"),
          name: "Amy Carson",
        },
        {
          userImg: require("@/assets/images/portrait/small/avatar-s-8.jpg"),
          name: "Brandon Miles",
        },
        {
          userImg: require("@/assets/images/portrait/small/avatar-s-7.jpg"),
          name: "Daisy Weber",
        },
        {
          userImg: require("@/assets/images/portrait/small/avatar-s-20.jpg"),
          name: "Jenny Looper",
        },
      ],
    };
  },
  created() {},
};
</script>
<style></style>
