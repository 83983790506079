<template>
  <div>
  <b-card>
    <h4>{{ $t("edit_information") }}</h4>
    <validation-observer ref="updateProfileValidation" name="UpdateProfile">
      <!-- user suggestion  -->

      <b-form>
        <b-row v-if="user && !showLoader">
          <b-col md="6">
            <b-form-group
                id="profile-email-address"
                :label="$t('email') + ':'"
                label-for="profile-email-address"
            >
              <b-form-input
                  id="profile-email-address"
                  v-model.trim="form.email"
                  type="email"
                  required
              ></b-form-input>
            </b-form-group>
            <b-form-group
                id="main-contact-tel-group"
                :label="$t('mobile_phone') + ':'"
                label-for="main-contact-tel"
            >
              <validation-provider
                  #default="{ errors }"
                  name="main-contact-tel"
              >
                <b-input-group>
                  <b-input-group-prepend id="phoneCountries">
                    <b-form-select
                        :options="phoneCountriesList"
                        v-model.trim="form.iso_code"
                        class="form-control"
                        :state="errors.length > 0 ? false : null"
                    >
                    </b-form-select>
                  </b-input-group-prepend>
                  <b-form-input
                      type="number"
                      id="main-contact-tel"
                      v-model.trim="form.mobile"
                      @input="checkPhoneFormat"
                      class="form-control"
                      :state="errors.length > 0 ? false : null"
                  >
                  </b-form-input>
                </b-input-group>

                <small class="text-danger">{{ $t(errors[0]) }}</small>
                <small class="text-danger" v-if="phoneValidation">{{
                  phoneValidation
                  }}</small>
              </validation-provider>
            </b-form-group>
            <b-form-group
                id="main-contact-gender-group"
                :label="$t('gender') + ':'"
                label-for="main-contact-gender"
            >
              <b-form-select
                  id="main-contact-gender"
                  v-model.trim="form.gender"
                  :options="[
                  {
                    text: $t('select_one'),
                    value: null,
                    disabled: true,
                  },
                  { text: $t('mr'), value: '0' },
                  { text: $t('mrs'), value: '1' },
                ]"
                  required
              ></b-form-select>
            </b-form-group>
            <b-form-group
                id="profile-first-name"
                :label="$t('first_name') + ':'"
                label-for="profile-first-name"
            >
              <b-form-input
                  id="profile-first-name"
                  v-model.trim="form.first_name"
                  required
              ></b-form-input>
            </b-form-group>
            <b-form-group
                id="profile-last-name"
                :label="$t('last_name') + ':'"
                label-for="profile-last-name"
            >
              <b-form-input
                  id="profile-last-name"
                  v-model.trim="form.last_name"
                  required
              ></b-form-input>
            </b-form-group>

            <b-form-group
                id="profile-birthday"
                :label="$t('birthday') + ':'"
                label-for="profile-birthday"
            >
              <b-form-datepicker
                  v-model.trim="form.birthday"
                  id="profile-birthday"
                  no-flip
                  required
              ></b-form-datepicker>
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group
                id="company-address-group"
                :label="$t('address') + ':'"
                label-for="company-address"
            >
              <validation-provider #default="{ errors }" name="Company Address">
                <b-form-input
                    id="company-address"
                    v-model.trim="form.address"
                    :state="errors.length > 0 ? false : null"
                    required
                    :disabled="disableInputs"
                ></b-form-input>
                <small class="text-danger">{{ $t(errors[0]) }}</small>
              </validation-provider>
            </b-form-group>

            <b-form-group
                id="company-address-nr-group"
                :label="$t('address_nr') + ':'"
                label-for="company-address-nr"
            >
              <validation-provider
                  #default="{ errors }"
                  name="Company Address Nr"
              >
                <b-form-input
                    id="company-address-nr"
                    v-model.trim="form.address_nr"
                    required
                    :state="errors.length > 0 ? false : null"
                    :disabled="disableInputs"
                ></b-form-input>
                <small class="text-danger">{{ $t(errors[0]) }}</small>
              </validation-provider>
            </b-form-group>
            <b-form-group
                id="company-zip-group"
                :label="$t('zip') + ':'"
                label-for="company-zip"
            >
              <validation-provider #default="{ errors }" name="Company Zip">
                <b-form-input
                    id="company-zip"
                    v-model.trim="form.zip"
                    required
                    :state="errors.length > 0 ? false : null"
                    :disabled="disableInputs"
                ></b-form-input>
                <small class="text-danger">{{ $t(errors[0]) }}</small>
              </validation-provider>
            </b-form-group>
            <b-form-group
                id="company-city-group"
                :label="$t('city') + ':'"
                label-for="company-city"
            >
              <validation-provider #default="{ errors }" name="Company City">
                <b-form-input
                    id="company-city"
                    v-model.trim="form.city"
                    required
                    :state="errors.length > 0 ? false : null"
                    :disabled="disableInputs"
                ></b-form-input>
                <small class="text-danger">{{ $t(errors[0]) }}</small>
              </validation-provider>
            </b-form-group>
            <b-form-group
                id="domicile-country-group"
                :label="$t('domicile_country') + ':'"
                label-for="domicile-country"
            >
              <validation-provider #default="{ errors }" name="Country">
                <b-form-select
                    id="domicile-country"
                    v-model.trim="form.country_id"
                    :options="countries"
                    :state="errors.length > 0 ? false : null"
                    required
                    :disabled="disableInputs"
                ></b-form-select>
                <small class="text-danger">{{ $t(errors[0]) }}</small>
              </validation-provider>
            </b-form-group>
            <b-form-group
                v-if="company.role != 'buyer'"
                id="profile-position"
                :label="$t('position_in_the_company') + ':'"
                label-for="profile-position"
            >
              <b-form-input
                  id="profile-position"
                  v-model.trim="form.position"
                  required
              ></b-form-input>
            </b-form-group>
          </b-col>
        </b-row>

        <b-col cols="12" v-if="showLoader">
          <img src="/new-loader.svg" />
          <!-- {{ JSON.stringify(company_zefix_data) }} -->
        </b-col>

        <b-row class="col-md-12 text-end justify-content-end p-0 pr-0 m-0">
          <b-button
              @click="$emit('showInfo', false)"
              type="button"
              variant="outline-primary"
              class="outlined mr-2"
          >{{ $t("close") }}
          </b-button>
          <b-button @click="onSubmit()" type="button" variant="primary"
          >{{ $t("save") }}
          </b-button>
        </b-row>
      </b-form>
    </validation-observer>

    <!--/ user suggestion  -->
  </b-card>
  <b-card>
      <h4>{{ $t("change_password") }}</h4>
      <validation-observer ref="updateProfileValidation" name="UpdateProfile">
        <!-- user suggestion  -->

        <b-form>
          <b-row v-if="user && !showLoader">
            <b-col md="6">
              <b-form-group
                  id="current-password-group"
                  :label="$t('current_password')"
                  label-for="current_password"
              >
                <validation-provider #default="{ errors }" name="Current Password">
                  <b-form-input
                      id="current_password"
                      v-model.trim="password.current_password"
                      required
                      :state="errors.length > 0 ? false : null"
                      :disabled="disableInputs"
                      type="password"
                  ></b-form-input>
                  <small class="text-danger" v-if="passwordErrors && passwordErrors.current_password">{{ passwordErrors.current_password[0] }}</small>
                </validation-provider>
              </b-form-group>
              <b-form-group
                  id="new-password-group"
                  :label="$t('new_password')"
                  label-for="new_password"
              >
                <validation-provider #default="{ errors }" name="New Password">
                  <b-form-input
                      id="new_password"
                      v-model.trim="password.new_password"
                      required
                      :state="errors.length > 0 ? false : null"
                      :disabled="disableInputs"
                      type="password"
                  ></b-form-input>
                  <small class="text-danger" v-if="passwordErrors && passwordErrors.new_password">{{ passwordErrors.new_password[0] }}</small>
                </validation-provider>
              </b-form-group>
              <b-form-group
                  id="confirm-password-group"
                  :label="$t('confirm_password')"
                  label-for="confirm_password"
              >
                <validation-provider #default="{ errors }" name="Confirm Password">
                  <b-form-input
                      id="confirm_password"
                      v-model.trim="password.confirm_password"
                      required
                      :state="errors.length > 0 ? false : null"
                      :disabled="disableInputs"
                      type="password"
                  ></b-form-input>
                  <small class="text-danger" v-if="passwordErrors && passwordErrors.confirm_password">{{ passwordErrors.confirm_password[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>

          <b-col cols="12" v-if="showLoader">
            <img src="/new-loader.svg" />
            <!-- {{ JSON.stringify(company_zefix_data) }} -->
          </b-col>

          <b-row class="col-md-12 text-end justify-content-end p-0 pr-0 m-0">

            <b-button @click="changePassword()" type="button" variant="primary"
            >{{ $t("save") }}
            </b-button>
          </b-row>
        </b-form>
      </validation-observer>

      <!--/ user suggestion  -->
</b-card>

    <b-card>
      <b-card-title> {{ $t("two_factor") }}</b-card-title>
      <b-card-text> {{ $t("two_factor_description") }}</b-card-text>
      <b-button v-if="!is_twoFactor" class="float-right" @click="openTwoFactorModal" variant="primary"> {{ $t("change_status") }} </b-button>
    </b-card>

    <b-modal v-model="twoFactor" style="padding-top: 20vh;" scrollable size="md" hide-header no-close-on-backdrop hide-footer >
      <div>
        <two-factor @toggle-two-factor="toggleTwoFactor"></two-factor>
      </div>
    </b-modal>

  </div>

    <!--/ user suggestion  -->
</template>

<script>
import Ripple from "vue-ripple-directive";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { mapGetters } from "vuex";
import Cleave from "vue-cleave-component";
import { phone } from "phone";
import moment from "moment";
import TwoFactor from "@/views/auth/TwoFactor.vue";

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    Cleave,
    TwoFactor
  },
  directives: {
    Ripple,
  },
  props: {
    user: {
      type: Object,
      default: () => {},
    },
    passwordErrors: Object
  },
  data() {
    return {
      form: {
        name: "",
        last_name: "",
        birthday: "",
        email: "",
        gender: "",
        position: "",
        mobile: "",
        address: "",
        address_nr: "",
        city: "",
        zip: "",
        country_id: "",
        country_name: "",
        nationality: "",
        language: "German",
      },
      password: {
        'current_password': '',
        'new_password': '',
        'confirm_password': '',
      },
      disableInputs: false,
      show: true,
      showLoader: true,
      countries: [],
      phoneContries: "CH",
      phoneValidation: "",
      phoneCountriesList: [],
      countries_code: [],
      twoFactor: false,
      is_twoFactor: null,
      phoneOptions: {
        phone: {
          delimiters: [" ", " ", " "],
          blocks: [2, 3, 2, 2],
        },
      },
    };
  },
  created() {
    this.$http.post("/countries").then((res) => {
      res.data.value.map((item) => {
        this.countries_code.push(item);
        this.phoneCountriesList.push({
          value: item.iso_code,
          text: `${item.iso_code} (${item.phone_code})`,
        });
        this.countries.push({
          value: item.id,
          text: item.name,
        });
      });

      this.getData();
      this.getTwoFactorInfo();
    });
  },
  methods: {
    getTwoFactorInfo() {
      this.$http
          .post(`check-user-two-factor/${this.$store.state.auth.user.id}`, {
            "check_code": true,
            "send_code": true
          })
          .then((res) => {
            if (res.data.value.bool) {
              this.is_twoFactor = true
            }
          });
    },
    toggleTwoFactor() {
      this.twoFactor = !this.twoFactor;
      this.getTwoFactorInfo()
    },
    openTwoFactorModal() {
      this.twoFactor = true
    },
    getData() {
      this.form = this.user;
      this.form.birthday = moment(
        this.form.birthday,
        "DD.MM.YYYY"
      ).format("YYYY-MM-DD"),
      this.form.mobile = this.getPhone(this.form.iso_code, this.form.mobile);
      this.showLoader = false
    },
    getPhone(iso, tel) {
      if (this.countries_code && this.countries_code.length) {
        this.countries_code.map((i, item) => {
          if (i.iso_code == iso) {
            tel = tel.replace(i.phone_code, "");
          }
        });
      }

      return tel;
    },
    setDate() {
      /* Increase day by 1 */
      const newDate = new Date(this.form.birthday);
      newDate.setDate(newDate.getDate() + 1);

      this.form.birthday = newDate;
    },
    checkPhoneFormat() {
      let validation = phone(this.form.mobile, {
        country: this.form.iso_code,
        validateMobilePrefix: false
      });

      if (validation && validation.isValid == false) {
        this.phoneValidation = `Phone for ${this.form.iso_code} must be valid`;
      } else {
        this.phoneValidation = "";
      }
    },
    searchForCompaniesName() {
      if (this.form.nationality.length > 2) {
        this.$http
          .post("/get-companies-name-from-zefix", {
            search: this.form.nationality,
          })
          .then((res) => {
            this.companies = res.data.value;
          });
      }
    },
    getCompanyDataFromZefix() {
      this.showLoader = true;
      if (typeof this.companies[this.form.nationality] != "undefined") {
        this.$http
          .post("/get-company-data-from-zefix", {
            search: this.companies[this.form.nationality],
          })
          .then((res) => {
            this.showLoader = false;
          });
      }
    },
    async onSubmit() {
      this.$emit("editForm", this.form);
      this.form = {
        name: "",
        last_name: "",
        birthday: "",
        email: "",
        gender: "",
        position: "",
        mobile: "",
        address: "",
        address_nr: "",
        city: "",
        zip: "",
        country_id: "",
        country_name: "",
        nationality: "",
      };
    },
    async changePassword() {
        this.$emit('changePassword', this.password)
    },
    onReset() {
      Object.keys(this.form).forEach((key) => {
        this.form[key] = "";
      });
    },
  },

  computed: {
    company() {
      return this.$store.state.app.watchCompany.value;
    },
  },
};
</script>
